import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage';
import { HomePage } from './pages/HomePage';
import { CustomersPage } from './pages/CustomersPage';  // Import the CustomersPage
import { LoginUtil } from 'common.f6st.com';
import { CallerType } from 'base.f6st.com';
import { AppProvider } from './components/AppProvider';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={LoginUtil.isAuthenticationToken(CallerType.ROOT) ? <HomePage /> : <Navigate to="/login" />} />
      <Route path="/customers" element={LoginUtil.isAuthenticationToken(CallerType.ROOT) ? <CustomersPage /> : <Navigate to="/login" />} />
    </Routes>
  );
};

export const App: React.FC = () => {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
};