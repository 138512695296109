import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { Grid, Typography, FormLabel, Skeleton } from '@mui/material';
import {
  APIProvider,
  ControlPosition,
  MapControl,
  AdvancedMarker,
  Map,
  useMap,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { useTranslation } from 'react-i18next';
import { Config } from '../util/Config';
import { GeoLocationUtil } from '../util/GeoLocationUtil';
import { Address, Log } from 'base.f6st.com';

type AddressAutocompleteProps = {
  fieldName: string;
  countryCode: string;
  label?: string;
  defaultPlaceId?: string;
  setFieldValue: (field: string, value: any) => void;
  setValidationError: (error: string) => void;
};

const addressValidationSchema = Yup.object().shape({
  city: Yup.string().required("City is required."),
  street: Yup.string().required("Street is required."),
  streetNumber: Yup.string().required("Street number is required."),
  zip: Yup.string().required("Zip Code is required."),
});

export const AddressSelection: React.FC<AddressAutocompleteProps> = ({
  fieldName,
  countryCode: country,
  label,
  defaultPlaceId,
  setFieldValue,
  setValidationError,
}) => {
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const { i18n } = useTranslation();
  const language = i18n.language;
  const [defaultCenter, setDefaultCenter] = useState<google.maps.LatLngLiteral | undefined>(undefined);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchDefaultCenter = async () => {
      const center = await GeoLocationUtil.getLatLng();
      Log.debug('Fetched defaultCenter:', center);
      if (center) setDefaultCenter(center);
    };

    if (!defaultPlaceId) fetchDefaultCenter();
  }, [defaultPlaceId]);

  const validateAddress = (address: Address) => {
    addressValidationSchema.validate(address)
      .then(() => {
        setValidationError('');
      })
      .catch((validationError) => {
        setValidationError(validationError.errors.join(', '));
      });
  };

  const onPlaceSelect = async (place: google.maps.places.PlaceResult | null) => {
    setSelectedPlace(place);
    if (place && place.address_components && place.geometry && place.place_id) {
      const formattedAddressWithoutCountry = place.formatted_address?.split(',').slice(0, -1).join(',') || '';
      setInputValue(formattedAddressWithoutCountry);
      const addressComponents = place.address_components;
      const city = addressComponents.find(comp => comp.types.includes('locality'))?.long_name || '';
      const street = addressComponents.find(comp => comp.types.includes('route'))?.long_name || '';
      const streetNumber = addressComponents.find(comp => comp.types.includes('street_number'))?.long_name || '';
      const zip = addressComponents.find(comp => comp.types.includes('postal_code'))?.long_name || '';
      const lat = place.geometry.location?.lat() || 0; // Default to 0 if undefined
      const lng = place.geometry.location?.lng() || 0; // Default to 0 if undefined

      const address: Address = {
        placeId: place.place_id,
        zip,
        city,
        street,
        streetNumber,
        lat,
        lng
      };

      setFieldValue(fieldName, address);
      setDefaultCenter({ lat, lng } as google.maps.LatLngLiteral);
      validateAddress(address);
    } else {
      Log.warn("Invalid place data", place);
    }
  };

  useEffect(() => {
    if (defaultPlaceId) {
      const service = new google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails({ placeId: defaultPlaceId, language }, (place, status) => {
        Log.debug('Fetched place details:', place);
        if (status === google.maps.places.PlacesServiceStatus.OK && place && place.address_components) {
          onPlaceSelect(place);
        }
      });
    }
  }, [defaultPlaceId, language]);

  useEffect(() => {
    if (selectedPlace) {
      const formattedAddressWithoutCountry = selectedPlace.formatted_address?.split(',').slice(0, -1).join(',') || '';
      setInputValue(formattedAddressWithoutCountry);
    }
  }, [selectedPlace]);

  useEffect(() => {
    return () => {
      if (marker && marker.map) {
        marker.map = null;
      }
    };
  }, [marker]);

  if (!defaultCenter) {
    Log.debug('defaultCenter is not set yet.');
    return (
      <div>
        <Skeleton variant="rectangular" width="100%" height="30vh" animation="wave" />
      </div>
    );
  }

  return (
    <>
      <style>{`
        .map-container {
          position: relative;
          height: 300px;
          width: 100%;
          margin-top: 16px;
        }
        .autocomplete-control {
          position: absolute; 
          top: 10px;
          left: 50%;
          width: 300px;
          transform: translateX(-50%);
        }
        .autocomplete-container input, .suggestion-item {
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          width: 290px;
        }
        .suggestions-container {
          background: white;
          border: 1px solid #ccc;
          position: absolute;
          width: 300px;
          z-index: 1000;
        }
        .suggestion-item {
          padding: 8px;
          cursor: pointer;
        }
        .suggestion-item:hover {
          background: #f0f0f0;
        }

        @media (min-width: 600px) {
          .autocomplete-container input, .suggestion-item {
            font-size: 16px;
            width: 400px;
          }
          .autocomplete-control {
            width: 400px;
          }
          .suggestions-container {
            width: 400px;
          }
        }
      `}</style>
      <APIProvider apiKey={Config.GOOGLE_MAPS_API_KEY} solutionChannel='GMP_devsite_samples_v3_rgmautocomplete'>
        <Grid container spacing={2}>
          <Grid item xs={12} mb={2}>
            {label && (
              <FormLabel component="legend">{label}</FormLabel>
            )}
            <div className="map-container">
              <Map
                mapId={'bf51a910020fa25a'}
                defaultZoom={3}
                center={defaultCenter}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
              >
                <AdvancedMarker ref={markerRef} position={selectedPlace?.geometry?.location || null} />
              </Map>
              <MapControl position={ControlPosition.TOP}>
                <div className="autocomplete-control">
                  <PlaceAutocomplete
                    onPlaceSelect={onPlaceSelect}
                    country={country}
                    inputRef={inputRef}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                  />
                </div>
              </MapControl>
              <MapHandler place={selectedPlace} marker={marker} />
            </div>
          </Grid>
        </Grid>
      </APIProvider>
    </>
  );
};

interface MapHandlerProps {
  place: google.maps.places.PlaceResult | null;
  marker: google.maps.marker.AdvancedMarkerElement | null;
}

const MapHandler = ({ place, marker }: MapHandlerProps) => {
  const map = useMap();

  useEffect(() => {
    if (!map || !place || !marker) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry?.viewport);
    } else if (place.geometry?.location) {
      map.setCenter(place.geometry?.location);
      map.setZoom(15);
    }
    marker.position = place.geometry?.location;
  }, [map, place, marker]);

  useEffect(() => {
    return () => {
      if (marker && marker.map) {
        marker.map = null;
      }
    };
  }, [marker]);

  return null;
};

interface PlaceAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  country: string;
  inputRef: React.RefObject<HTMLInputElement>;
  inputValue: string;
  setInputValue: (value: string) => void;
}

const PlaceAutocomplete = ({ onPlaceSelect, country, inputRef, inputValue, setInputValue }: PlaceAutocompleteProps) => {
  const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const placesServiceRef = useRef<google.maps.places.AutocompleteService | null>(null);

  useEffect(() => {
    if (!placesServiceRef.current) {
      placesServiceRef.current = new google.maps.places.AutocompleteService();
    }
  }, []);

  const fetchSuggestions = (input: string) => {
    if (placesServiceRef.current) {
      placesServiceRef.current.getPlacePredictions(
        { input, componentRestrictions: { country }, types: ['address'] },
        (predictions) => {
          if (predictions) {
            setSuggestions(predictions);
          }
        }
      );
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    fetchSuggestions(inputValue);
  };

  const handleSuggestionClick = (suggestion: google.maps.places.AutocompletePrediction) => {
    const service = new google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails({ placeId: suggestion.place_id }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && place) {
        onPlaceSelect(place);
      }
    });
    setSuggestions([]);
    setInputValue(suggestion.description.split(',').slice(0, -1).join(','));
  };

  return (
    <div className="autocomplete-container">
      <input
        ref={inputRef}
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Enter an address"
        autoComplete="off"
      />
      {suggestions.length > 0 && (
        <div className="suggestions-container">
          {suggestions.map((suggestion) => (
            <div
              key={suggestion.place_id}
              className="suggestion-item"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion.description.split(',').slice(0, -1).join(',')}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
