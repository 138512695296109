import { CallerType, Customer, Log, QrCode, ProductsTexts, CustomerUpdateAttributes, Products } from 'base.f6st.com';
import { ApiUtil } from '../util/ApiUtil';

export class CustomerClient {

  public static async getById(caller: CallerType, customerId: string, languageCode?: string): Promise<Customer | undefined> {
    Log.debug('Loading customer data from DynamoDB', { customerId, languageCode });
    if (ApiUtil.isLocalApiAvailable()) {
      const CustomerApi = await this.getCustomerApi();
      return await CustomerApi.getById(customerId, languageCode);
    } else {
      return await ApiUtil.postRequest<Customer>(caller, "customer/getById", { customerId, languageCode });
    }
  }

  public static async getProductsTexts(caller: CallerType, customerId: string, sourceLang: string, targetLang?: string): Promise<ProductsTexts> {
    Log.debug('Loading products texts from DynamoDB', { customerId, sourceLang, targetLang });
    if (ApiUtil.isLocalApiAvailable()) {
      const CustomerApi = await this.getCustomerApi();
      return await CustomerApi.getProductsTexts(customerId, sourceLang, targetLang);
    } else {
      return await ApiUtil.postRequest<ProductsTexts>(caller, "customer/getProductsTexts", { customerId, sourceLang, targetLang });
    }
  }

  public static async updateCustomer(caller: CallerType, customerId: string, updates: { attribute: CustomerUpdateAttributes, value: any }[]): Promise<void> {
    Log.debug('Updating customer data in DynamoDB', { customerId, updates });
    if (ApiUtil.isLocalApiAvailable()) {
      const CustomerApi = await this.getCustomerApi();
      await CustomerApi.updateCustomer(customerId, updates);
    } else {
      await ApiUtil.postRequest<void>(caller, "customer/updateCustomer", { customerId, updates });
    }
  }

  public static async getAllCustomers(caller: CallerType): Promise<Array<Pick<Customer, 'id' | 'active' | 'businessSettings'>>> {
    Log.debug('Loading all customers from DynamoDB');
    if (ApiUtil.isLocalApiAvailable()) {
      const CustomerApi = await this.getCustomerApi();
      return await CustomerApi.getAllCustomers();
    } else {
      return await ApiUtil.postRequest<Array<Pick<Customer, 'id' | 'active' | 'businessSettings'>>>(caller, "customer/getAllCustomers", {});
    }
  }  

  public static async createCustomer(caller: CallerType, customer: Customer): Promise<void> {
    Log.debug('Creating customer in DynamoDB', customer);
    if (ApiUtil.isLocalApiAvailable()) {
      const CustomerApi = await this.getCustomerApi();
      await CustomerApi.createCustomer(customer);
    } else {
      await ApiUtil.postRequest<void>(caller, "customer/createCustomer", { customer });
    }
  }

  public static async updateProductsPrimary(caller: CallerType, customerId: string, products: Products, productsTexts: ProductsTexts, languageCode: string): Promise<void> {
    Log.debug('Updating products primary language in DynamoDB', { customerId, languageCode });
    if (ApiUtil.isLocalApiAvailable()) {
      const CustomerApi = await this.getCustomerApi();
      await CustomerApi.updateProductsPrimary(customerId, products, productsTexts, languageCode);
    } else {
      await ApiUtil.postRequest<void>(caller, "customer/updateProductsPrimary", { customerId, products, productsTexts, languageCode });
    }
  }

  public static async getCustomerApi() {
    // @ts-ignore: Dynamic import may not resolve during production build
    return (await import('api.f6st.com')).CustomerApi;
  }
}
