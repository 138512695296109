import React from 'react';
import { Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { CustomAppBar } from '../components/CustomAppBar';

type HomePageProps = {};

export const HomePage: React.FC<HomePageProps> = () => {
  const navigate = useNavigate();

  const card = {
    title: "Customers",
    description: "List all customers and login to their apps.",
    url: "/customers",
    icon: "/assets/ic_orders.svg",
    color: "#4CAF50",
  };

  return (
    <>
      <CustomAppBar pageTitle="Home" />

      <Container>
        <Grid container spacing={2} columns={12} mt={2}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Link to={card.url} style={{ textDecoration: "none" }}>
              <Paper
                elevation={3}
                sx={{
                  padding: "24px",
                  height: 108,
                  display: "flex",
                  flexDirection: "column",
                  color: "#FFF",
                  borderRadius: "16px",
                  gap: 2,
                  position: "relative",
                  backgroundColor: card.color,
                }}
              >
                <img width="32px" src={card.icon} alt={`${card.title} Icon`} />
                <Stack direction="column" gap="4px">
                  <Typography variant="h5">Customer Login</Typography>
                  <Typography color="#ffffff99" variant="body1">
                    Login to the customer's admin and ordering page.
                  </Typography>
                </Stack>
                <img
                  src="/assets/card-img.png"
                  style={{ position: "absolute", top: 0, right: 0, height: "100%" }}
                  alt="Background Image"
                />
              </Paper>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
