import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const startApp = async () => {
  try {
    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    );
    console.debug("App started successfully");
  } catch (error) {
    console.error("Failed to start app", error);
    root.render(
      <div>
        <h1>Failed to load the application</h1>
        <p>Please try refreshing the page or contact us.</p>
      </div>
    );
  }
};

startApp();
