import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Container } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CallerType, Log } from 'base.f6st.com';
import { CustomerClient } from 'common.f6st.com';
import { CustomAppBar } from '../components/CustomAppBar';
import { useAppContext } from '../components/AppProvider'; // Import the AppContext

interface Customer {
  id: string;
  active: boolean;
  businessSettings: {
    businessName?: string;
    [key: string]: any;
  };
}

export const CustomersPage: React.FC = () => {
  const { username, password } = useAppContext();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const result = await CustomerClient.getAllCustomers(CallerType.ROOT);
        setCustomers(result);
      } catch (err) {
        setError('Failed to load customers');
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, []);

  const getOrderLink = (customerId: string) => {
    return `https://order.f6st.com/?c=ZCSQJY:${customerId}:1`;
  };

  const getAdminLink = (customerId: string) => {
    // Generate the admin link with username, password, and customerId as URL parameters
    const url = new URL('http://admin.f6st.com/loginRoot');
    url.searchParams.append('u', username);
    url.searchParams.append('p', password);
    url.searchParams.append('c', customerId);
    return url.toString();
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <>
      <CustomAppBar backButton pageTitle="Customers" />

      <Container>
        <Paper elevation={3} sx={{ padding: '24px', marginTop: '16px' }}>
          <TableContainer component={Paper} elevation={0} sx={{ boxShadow: 'none' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Business Settings</TableCell>
                  <TableCell>Links</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((customer) => (
                  <TableRow key={customer.id}>
                    <TableCell>{customer.id}</TableCell>
                    <TableCell>{customer.active ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>{customer.businessSettings.businessName || 'Unknown Business Name'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {Object.entries(customer.businessSettings).map(([key, value]) => (
                            <div key={key}>
                              <strong>{key}:</strong> {String(value)}
                            </div>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </TableCell>
                    <TableCell>
                      <a href={getOrderLink(customer.id)} target="_blank" rel="noopener noreferrer">Order</a> | <a href={getAdminLink(customer.id)}>Admin</a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </>
  );
};
