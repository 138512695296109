import React, { useState } from 'react';
import { Container, TextField, Typography, Box, Paper } from '@mui/material';
import { StandardButton } from 'common.f6st.com';
import { useAppContext } from '../components/AppProvider';

export const LoginPage: React.FC = () => {
  const { login } = useAppContext();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [localError, setLocalError] = useState('');

  const handleLogin = async () => {
    try {
      await login(username, password);
    } catch (err) {
      setLocalError('Login failed');
    }
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 8 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Login
          </Typography>
        </Box>
        {localError && (
          <Typography color="error" sx={{ mb: 2 }}>
            {localError}
          </Typography>
        )}
        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mb: 2 }}
        />
        <StandardButton 
          text="Login"
          action={handleLogin}
          style={{ marginTop: '16px' }}
        />
      </Paper>
    </Container>
  );
};
