import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from 'common.f6st.com';
import { useAppContext } from '../components/AppProvider'; // Assuming AppProvider is in the components folder

type CustomAppBarProps = {
  backButton?: boolean;
  pageTitle: string;
};

export const CustomAppBar = ({ backButton = false, pageTitle }: CustomAppBarProps) => {
  const navigate = useNavigate();
  const { logout } = useAppContext(); 

  const handleLogout = () => {
    logout(); // Call the logout function from the context
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ bgcolor: 'white', paddingLeft: backButton ? 1 : 2 }}>
        {backButton && (
          <SecondaryButton
            text="Back"
            action={() => navigate(-1)}
            style={{ width: '80px', marginRight: '8px' }}
          />
        )}

        <Typography
          color="black"
          textAlign="center"
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        >
          {pageTitle}
        </Typography>

        <SecondaryButton
          text="Logout"
          action={handleLogout}
          style={{ width: '80px', marginLeft: 'auto' }}
        />
      </Toolbar>
    </AppBar>
  );
};
