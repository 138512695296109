import React, { createContext, useContext, useState, ReactNode, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginClient, LoginUtil } from 'common.f6st.com';
import { CallerType } from 'base.f6st.com';

type AppContextType = {
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  error: string;
  username: string;
  password: string;
};

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const login = async (user: string, pass: string): Promise<void> => {
    try {
      const success = await LoginClient.loginRoot(user.trim(), pass.trim());
      if (success) {
        setUsername(user.trim());
        setPassword(pass.trim());
        navigate('/');
      } else {
        setError('Login failed');
      }
    } catch (err) {
      setError('Login failed');
    }
  };

  const logout = () => {
    LoginUtil.removeAuthenticationToken(CallerType.ROOT);
    setUsername(''); // Clear the username
    setPassword(''); // Clear the password
    navigate('/login'); // Redirect to login page
  };

  return (
    <AppContext.Provider value={{ login, logout, error, username, password }}>
      {children}
    </AppContext.Provider>
  );
};
